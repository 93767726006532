// modified from https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js

import { useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import { useCallback, useMemo } from 'react';

const defaultOptions = { debounce: 500, minLength: 3 };

const useDebouncedSearch = (
  searchFunction,
  options = defaultOptions,
  deps = []
) => {
  const [inputText, setInputText] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(searchFunction, deps);

  const debouncedSearchFunction = useMemo(
    () =>
      AwesomeDebouncePromise(
        callback,
        options.debounce ?? defaultOptions.debounce
      ),
    [callback, options.debounce]
  );

  const search = useAsync(async () => {
    if (inputText.length < (options.minLength ?? defaultOptions.minLength)) {
      return null;
    } else {
      return debouncedSearchFunction(inputText);
    }
  }, [debouncedSearchFunction, inputText]);

  return {
    inputText,
    setInputText,
    search,
  };
};

export default useDebouncedSearch;
