import React from 'react';
import { BasicLayout, Card, Footer } from 'src/common';
import Button from 'src/common/Button';
import { useModel } from 'src/lib/hooks';

const IssuanceFailure = ({ referenceNumber }) => {
  const { ID } = useModel.quote();

  return (
    <BasicLayout displayNavElements={false}>
      <Card icon="iceCream" title="Oh, no. Something went wrong.">
        <p className="text-center mb-5">
          Sorry — we were unable to issue your policy. Please call us at{' '}
          <a
            href="tel:18005570964"
            className="underline text-curious-blue-dark"
          >
            1-800-557-0964
          </a>{' '}
          so we can help resolve the problem!
        </p>
        {referenceNumber && (
          <p className="text-violet text-center">
            <b>Reference Number: {referenceNumber}</b>
          </p>
        )}
        <p className="text-violet text-center">
          <b>Quote Number: {ID}</b>
        </p>
      </Card>
      <Footer>
        <Button href="tel:18005570964" className="focus:ring-offset-violet">
          1-800-557-0964
        </Button>
      </Footer>
    </BasicLayout>
  );
};

export default IssuanceFailure;
