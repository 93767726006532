import React from 'react';
import classNames from 'tailwindcss-classnames';
import Header from '../Header';

const BasicLayout = ({
  children,
  className,
  center,
  displayNavElements,
  displayEffectiveDate,
  displayConfetti,
  ...rest
}) => {
  return (
    <div
      className={classNames('flex flex-col mx-auto max-w-2xl pb-4', className)}
      {...rest}
    >
      <Header
        displayNavElements={displayNavElements}
        displayEffectiveDate={displayEffectiveDate}
        displayConfetti={displayConfetti}
      />
      {children}
    </div>
  );
};

export default BasicLayout;
