import config from 'src/config';

const debugData = (dataAttributes = {}) => {
  if (config.debug !== 'true') {
    return {};
  }

  return Object.keys(dataAttributes).reduce(
    (agg, next) => ({
      ...agg,
      [`data-debug-${next}`]: dataAttributes[next],
    }),
    {}
  );
};

export default debugData;
