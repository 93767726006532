import React from 'react';

import { MaskedInput } from 'src/common';
import { dateTransform } from 'src/lib/transforms';

const DateInput = ({
  label,
  name,
  placeholder = 'MM/DD/YYYY',
  defaultValue,
  error,
  control,
  containerClassName,
  rules,
  ...props
}) => (
  <MaskedInput
    containerClassName={containerClassName}
    label={label}
    name={name}
    error={error}
    defaultValue={defaultValue}
    mask="99/99/9999"
    placeholder={placeholder}
    control={control}
    rules={rules}
    transform={dateTransform}
    {...props}
  />
);

export default DateInput;
