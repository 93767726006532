import React from 'react';
import { useHistory } from 'react-router-dom';

import { BasicLayout, Card, Footer } from 'src/common';

const AccountUnblocked = () => {
  const history = useHistory();
  const handleResume = () => {
    history.replace('/resume');
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="padlock"
        title="Account unlocked."
        subtitle="Thanks for telling us it’s you!"
      />
      <Footer displayAssitance={false}>
        <Footer.Next rounded label="Back to my Quote" onClick={handleResume} />
      </Footer>
    </BasicLayout>
  );
};

export default AccountUnblocked;
