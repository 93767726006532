import { extendTailwindMerge } from 'tailwind-merge';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from './../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);
export const twMerge = extendTailwindMerge({
  classGroups: {
    width: Object.keys(fullConfig.theme.width).map((key) => `w-${key}`),
    height: Object.keys(fullConfig.theme.height).map((key) => `h-${key}`),
    fontSize: Object.keys(fullConfig.theme.fontSize).map(
      (key) => `text-${key}`
    ),
  },
});
