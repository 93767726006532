import { useModel } from 'src/lib/hooks';
import { BasicLayout, Card, Footer } from 'src/common';

const Maintenance = () => {
  const { Property } = useModel.quote();

  const handleExit = () => {
    window.location.assign('https://www.slideinsurance.com/');
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="toolkit"
        title="Our apologies."
        contentClassName="max-w-xl"
        subtitle={
          <>
            {!!Property?.Location
              ? 'Our systems started their routine maintenance service while you were working on that question, so your answers got lost at sea.'
              : 'Our systems’ routine maintenance service is underway.'}
            <strong className="block my-6 text-violet">
              Please try again later.
            </strong>
            Sorry about the inconvenience!
          </>
        }
      />
      <Footer>
        <Footer.Back
          rounded
          hideIcon={true}
          label="Exit"
          onClick={handleExit}
          className="w-105px"
        />
      </Footer>
    </BasicLayout>
  );
};

export default Maintenance;
