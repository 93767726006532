const suffixes = [
  { value: 'Jr.', name: 'Jr.' },
  { value: 'Sr.', name: 'Sr.' },
  { value: 'II', name: 'II' },
  { value: 'III', name: 'III' },
  { value: 'JD', name: 'JD' },
  { value: 'M.D.', name: 'M.D.' },
  { value: 'Ph.D.', name: 'Ph.D.' },
  { value: 'Esquire', name: 'Esquire' },
  { value: 'CPA', name: 'CPA' },
  { value: 'Other', name: 'Other' },
];

export default suffixes;
