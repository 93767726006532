import React, { useState } from 'react';
import classNames from 'tailwindcss-classnames';
import Tooltip from '../Tooltip';
const Input = React.forwardRef(
  (
    {
      name,
      id,
      type,
      label,
      error,
      containerClassName,
      className,
      maskedFocus = false,
      optional = false,
      textClass = 'text-xs',
      heightClass = 'h-11',
      onBlur = () => {},
      afterBlur = (e) => {},
      helpTextTitle,
      helpTextDescription,
      maxLength,
      onlyNumbers = false,
      allowDecimals = true,
      ...props
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false);

    const customOnBlur = (e) => {
      onBlur(e);
      afterBlur(e);
    };

    const onlyNumbersHandling = (evt) => {
      // Handle paste
      let key;
      if (evt.type === 'paste') {
        key = evt.clipboardData.getData('text/plain');
      } else {
        // Handle key press
        key = evt.keyCode || evt.which;
        key = String.fromCharCode(key);
      }
      let regex = allowDecimals ? /[0-9]|\./ : /[0-9]/;
      if (!regex.test(key)) {
        evt.returnValue = false;
        if (evt.preventDefault) {
          evt.preventDefault();
        }
      }
    };

    const inputType = type === 'dollar' ? 'number' : type;

    return (
      <div className={containerClassName}>
        <div className="flex flex-col relative">
          {label && (
            <label
              htmlFor={id ?? name}
              className={classNames(
                'bg-white px-1 font-bold absolute left-4',
                textClass,
                helpTextTitle || helpTextDescription ? '-mt-3' : '-mt-3'
              )}
            >
              <div>
                <p
                  className={classNames(
                    'font-bold text-xs inline leading-6',
                    error
                      ? 'text-error'
                      : focus || maskedFocus
                      ? 'text-violet'
                      : 'text-input-light'
                  )}
                >
                  {label}
                </p>
                {!!(helpTextTitle || helpTextDescription) && (
                  <Tooltip
                    className="inline-block"
                    place="top"
                    title={helpTextTitle}
                    text={helpTextDescription}
                    iconClassName="-top-0.5"
                  ></Tooltip>
                )}
              </div>
            </label>
          )}
          <div className="flex items-center">
            {type === 'dollar' && (
              <div className="text-sm absolute left-2">$</div>
            )}
            <input
              className={classNames(
                'w-full px-4 text-input border rounded-lg',
                heightClass,
                textClass,
                error
                  ? 'border-error ring-error-light'
                  : 'border-input-lighter ring-input-light-faded focus:border-violet ring-violet-faded',
                type === 'dollar' && 'pl-5',
                className
              )}
              required={!optional}
              ref={ref}
              aria-invalid={error ? 'true' : 'false'}
              {...(error && { 'aria-describedby': `${id ?? name}-error` })}
              type={inputType ?? 'text'}
              id={id ?? name}
              name={name}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={(e) => {
                setFocus(false);
                customOnBlur(e);
              }}
              data-testid={`${id ?? name}.input`}
              onKeyPress={onlyNumbers ? onlyNumbersHandling : null}
              maxLength={maxLength}
              {...props}
            />
          </div>
          {error && (
            <span
              id={`${id ?? name}-error`}
              className="text-error text-xs mt-1 ml-4"
            >
              {error}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
