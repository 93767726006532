/* eslint-disable jsx-a11y/role-supports-aria-props */
import { useSelect } from 'downshift';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import { DropdownMenu, Icon, Tooltip } from 'src/common';
import { useEffect, useMemo } from 'react';

const Select = ({
  id,
  name,
  label,
  placeholder,
  options: rawOptions = [],
  className,
  containerClassName,
  error: errorField = true,
  control,
  rules,
  defaultValue,
  disabledWhen,
  onChange = () => {},
  afterChange = (value) => {},
  helpTextTitle,
  helpTextDescription,
  optional = false,
  textClass = 'text-xs',
  heightClass = 'h-11',
  readOnly = false,
  displayedExtraValue,
  input = (v, options) => options.find((o) => o.id === v),
  output = (v) => v?.selectedItem?.id,
}) => {
  const options = useMemo(() => {
    if (!disabledWhen) {
      return rawOptions;
    }
    return rawOptions.map((value) => {
      value.disabled = disabledWhen(value);
      return value;
    });
  }, [rawOptions, disabledWhen]);

  const transform = useMemo(() => {
    return {
      input: input,
      output: output,
    };
  }, [input, output]);

  const { field } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const { onChange: onChangeField, value, ref } = field;

  let error;
  try {
    error = errorField === true ? control.getFieldError(name) : errorField;
  } catch (error) {}

  const handleOnChange = (value) => {
    onChange();
    onChangeField(value);
    afterChange(value);
  };

  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    selectItem,
  } = useSelect({
    items: options,
    onSelectedItemChange: (v) => {
      handleOnChange(transform.output(v));
    },
    defaultSelectedItem: transform.input(value, options),
    selectedItem: transform.input(value, options),
    itemToString: (i) => (i ? i.title : ''),
    id: id ?? name,
  });

  useEffect(() => {
    if (selectedItem?.disabled) {
      handleOnChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledWhen, options, selectItem, transform, value]);

  useEffect(() => {
    if (value === '') {
      selectItem(null);
    }
    //eslint-disable-next-line
  }, [value]);

  return (
    <div
      className={classNames(
        containerClassName ?? 'flex flex-col w-full',
        'relative'
      )}
    >
      {label && (
        <label
          htmlFor={id ?? name}
          className={classNames(
            'bg-white px-1 absolute ml-4 h-6',

            helpTextTitle || helpTextDescription ? '-mt-3' : '-mt-3'
          )}
          {...getLabelProps()}
        >
          <div>
            <p
              className={classNames(
                'font-bold text-xs inline leading-6',
                error
                  ? 'text-error'
                  : isOpen
                  ? 'text-violet'
                  : 'text-input-light'
              )}
            >
              {label}
            </p>
            {!!(helpTextTitle || helpTextDescription) && (
              <Tooltip
                className="inline-block"
                place="top"
                title={helpTextTitle}
                text={helpTextDescription}
                iconClassName="-top-0.5"
              ></Tooltip>
            )}
          </div>
        </label>
      )}
      <div>
        <button
          type="button"
          {...getToggleButtonProps({ ref, role: 'combobox' })}
          id={id ?? name}
          data-testid={`selectButton.${id ?? name}`}
          {...(error && { 'aria-describedby': `${id ?? name}-error` })}
          className={classNames(
            'flex justify-between bg-white items-center w-full border rounded-lg bg-clip-border transition px-4 cursor-default ',
            textClass,
            heightClass,
            error
              ? 'text-error border-error'
              : isOpen
              ? 'text-violet border-violet'
              : 'text-input border-input-lighter',
            className
          )}
          aria-required={!optional}
          disabled={readOnly}
        >
          <span
            data-testid="description-selected-item"
            className={classNames('truncate', {
              'text-input-light': selectedItem?.id === undefined,
            })}
          >
            {!selectedItem?.title || selectedItem?.disabled
              ? placeholder
              : selectedItem?.title}
          </span>
          {displayedExtraValue && (
            <div
              data-testid={`displayValue.${id ?? name}`}
              className="absolute text-xs right-9 text-[#b4bcce]"
            >
              {displayedExtraValue(value)}
            </div>
          )}
          <Icon
            name="chevronDown"
            size="sm"
            className={classNames('transform transition', {
              'rotate-180': isOpen,
            })}
            color={!!error && 'error'}
          />
        </button>
        <DropdownMenu
          id={`${id ?? name}-list`}
          isOpen={isOpen}
          error={error}
          options={options}
          highlightedIndex={highlightedIndex}
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
          getItemValue={(i) => i.title}
          textClass={textClass}
          disabled={readOnly}
        />
      </div>
      {error && (
        <span
          id={error ? `${id ?? name}-error` : undefined}
          className="text-error text-xs mt-1 ml-4"
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default Select;
