import { useRef, useState } from 'react';

const usePortal = (id) => {
  const idRef = useRef();
  const [container, setContainer] = useState(null);

  if (!container || idRef.current !== id) {
    const element = document.querySelector(`#${id}`);
    if (element) {
      setContainer(element);
    }
  }

  idRef.current = id;
  return container;
};

export default usePortal;
