import loadable from '@loadable/component';

const florida = {
  ho3: loadable(() => import('./florida/ho3')),
  ho6: loadable(() => import('./florida/ho6')),
};

const products = { florida };

export default products;
