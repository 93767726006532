import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  let searchString = useLocation().search;
  let searchParams = new URLSearchParams(searchString);
  let result = {};

  for (let value of searchParams.entries()) {
    result[value[0]] = value[1];
  }

  return result;
};

export default useQueryParams;
