const currentPhase = {
  state: {},
  reducers: {
    'quote/nextPhase': () => ({}),
    'appendCurrentPhase': (state, values) => ({ ...state, ...values }),
    'global/reset': () => ({}),
  },
};

export default currentPhase;
