import React from 'react';
import { BasicLayout, Footer, Card } from 'src/common';

const UnknownError = ({
  title = 'We are unable to proceed',
  description = 'The application has encountered an unknown error.',
  handleStartOver,
}) => (
  <BasicLayout displayNavElements={false}>
    <Card icon="beachClosed" title={title} subtitle={description} />
    <Footer>
      <Footer.Next rounded label="Exit" onClick={handleStartOver} icon={null} />
    </Footer>
  </BasicLayout>
);

export default UnknownError;
