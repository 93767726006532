import React from 'react';
import classNames from 'tailwindcss-classnames';

const AnnouncementBar = ({
  role,
  className,
  text,
  showLink,
  textLink,
  href,
  classNameLink,
}) => {
  return (
    <p
      role={role}
      className={classNames(
        'flex items-center flex-col justify-center text-center p-2',
        'sm:flex-row',
        className
      )}
    >
      {text}
      {showLink && (
        <a
          href={href}
          className={classNameLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {textLink}
        </a>
      )}
    </p>
  );
};

export default AnnouncementBar;
