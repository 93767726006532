import React, { useLayoutEffect, useRef } from 'react';

import { useModel } from 'src/lib/hooks';

const Refocus = (props) => {
  const divRef = useRef(null);
  const { page, coveragesPageIndex } = useModel.session();

  useLayoutEffect(() => {
    if (divRef && divRef.current) {
      divRef.current.focus();

      const scrollingEl = document.getElementById('scrolling-container');

      const { scrollY, innerHeight } = window;
      const { scrollHeight: bodyScrollHeight } = document.body;
      const { scrollTop, scrollHeight } = scrollingEl;

      if (scrollTop !== 0 && scrollHeight > innerHeight) {
        scrollingEl.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else if (scrollY !== 0 && bodyScrollHeight > innerHeight) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [page, coveragesPageIndex]);

  return (
    <div ref={divRef} tabIndex={-1} className="outline-none">
      {props.children}
    </div>
  );
};

export default Refocus;
