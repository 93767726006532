import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useController } from 'react-hook-form';

const useFile = ({ name, control, rules, defaultValue, options }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const fileRef = useRef(null);

  rules = {
    ...rules,
    validate: {
      ...rules?.validate,
      fileError: () => error,
    },
  };

  const { field } = useController({ name, control, rules, defaultValue });

  const { value, onChange } = field;

  const ref = (e) => {
    field.ref(e);
    fileRef.current = e; // you can still assign to ref
  };

  useEffect(() => {
    onChange(defaultValue);
    setFile(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!value) {
      setFile(null);
      if (fileRef.current) {
        fileRef.current.value = null;
      }
    }
  }, [value, fileRef]);

  const openFileBrowser = () => {
    fileRef.current.click();
  };
  const removeSelectedFile = () => {
    setError(null);
    setFile(null);
    fileRef.current.value = null;
    onChange(null);
  };

  const validateFileExtension = (file) => {
    const isValid = options.extensions.some(
      (x) => file.name.split('.').pop() === x.split('.').pop()
    );

    return isValid;
  };

  const validateFileSize = (file) => {
    const isValid = file.size < options.limitSize && file.size > 0;
    return isValid;
  };

  const validateFileNameLength = (file) => {
    const isValid = file.name.length <= options.maxFileNameLength;
    return isValid;
  };

  return {
    file,
    setFile,
    error,
    setError,
    ref,
    field: { ...field, ref: ref },
    openFileBrowser,
    removeSelectedFile,
    validateFileExtension,
    validateFileSize,
    validateFileNameLength,
  };
};

export default useFile;
