import { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import FocusTrap from 'focus-trap-react';
import { classnames } from 'tailwindcss-classnames';

import { Icon } from 'src/common';
import './Tooltip.css';
import useUId from 'src/lib/hooks/useUId';
import { useResponsiveVariant } from 'src/lib/hooks';
import { twMerge } from 'src/lib/mergeTailwind';

const TOOLTIP_MAX_WIDTH = 320;

const TooltipPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.querySelector('body'));

const Tooltip = ({
  icon = 'infoCircle',
  label,
  labelClassName,
  title,
  text,
  children,
  className,
  iconClassName = '',
  place = 'bottom',
  size,
  color = 'curious-blue',
  hideTitle = false,
}) => {
  const labelId = useUId();
  const tooltipId = useUId();
  const btnRef = useRef(null);
  const tooltipRef = useRef(null);
  const [open, setOpen] = useState(false);
  const isMobile = useResponsiveVariant({ default: true, sm: false });

  const handleOpen = (e) => {
    btnRef.current.blur();
    setOpen(true);
  };

  const calculateNewPosition = (pos) => {
    let newPos = { ...pos };
    const calculatedWidth = (window.innerWidth * 10) / 12;
    const tooltipWidth =
      calculatedWidth > TOOLTIP_MAX_WIDTH ? TOOLTIP_MAX_WIDTH : calculatedWidth;

    if (!isMobile) {
      return newPos;
    }

    if (pos.left < 0) {
      newPos.left = 30;
    }
    if (pos.left + tooltipWidth > window.innerWidth) {
      newPos.left = window.innerWidth - tooltipWidth - 30;
    }
    if (pos.top < 0) {
      newPos.top = 30;
    }

    return newPos;
  };

  const handleClose = () => {
    if (tooltipRef && tooltipRef.current) {
      // Why this? https://github.com/wwayne/react-tooltip/issues/449
      tooltipRef.current.tooltipRef = null;
    }
    ReactTooltip.hide();
  };

  useEffect(() => {
    const onKeyup = (e) => {
      if (open && e.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [open]);

  return (
    <div className={className}>
      <button
        type="button"
        data-event="click"
        data-for={tooltipId}
        data-tip={tooltipId}
        className={classnames(
          'flex items-center text-curious-blue-dark focus:outline-none focus:ring-4 focus:ring-curious-blue-light',
          {
            'rounded-full': icon === 'infoCircle' && !children,
          }
        )}
        aria-label={title || text}
        aria-controls={tooltipId}
        title={title || text}
        ref={btnRef}
        onClick={() => setOpen(true)}
        data-testid={`${tooltipId}-openTooltip`}
      >
        {children ? (
          <>
            {children}
            <Icon name={icon} color={color} size={size} />
          </>
        ) : (
          <p className={labelClassName}>
            {label}
            <span className="text-nowrap">
              &nbsp;
              <Icon
                className={twMerge(
                  'inline-block relative -top-1',
                  iconClassName
                )}
                name={icon}
                color={color}
                size={size}
              />
            </span>
          </p>
        )}
      </button>
      <TooltipPortal>
        <FocusTrap active={open}>
          <div>
            <ReactTooltip
              id={tooltipId}
              place={isMobile ? 'auto' : place}
              effect="solid"
              event="click"
              arrowColor="#fff"
              multiline={true}
              clickable
              afterShow={handleOpen}
              afterHide={() => setOpen(false)}
              ref={tooltipRef}
              overridePosition={calculateNewPosition}
              role="dialog"
              aria-modal="true"
              aria-labelledby={labelId}
            >
              <div className="py-8 w-auto md:w-302px max-h-80 md:max-h-screen px-6 text-left rounded-lg bg-white mx-5 sm:mx-0 relative outline-none overflow-scroll md:overflow-hidden">
                <button
                  type="button"
                  className="absolute right-0 top-0 m-2.5 p-0.5 "
                  onClick={handleClose}
                  data-testid={`${tooltipId}-closeTooltip`}
                >
                  <Icon name="cancel" size="2sm" />
                  <span className="sr-only">Close</span>
                </button>
                <h1
                  data-testid={`${tooltipId}-tooltipTitle`}
                  className={classnames(
                    'text-lg text-violet font-bold font-content leading-tight pb-2',
                    { 'sr-only': hideTitle }
                  )}
                  id={labelId}
                >
                  {title}
                </h1>
                {typeof text === 'string' ? (
                  <p
                    data-testid={`${tooltipId}-tooltipText`}
                    className="mt-2 text-violet text-sm font-normal"
                  >
                    {text}
                  </p>
                ) : (
                  <div
                    data-testid={`${tooltipId}-tooltipText`}
                    className="tooltip-content"
                  >
                    {text}
                  </div>
                )}
              </div>
            </ReactTooltip>
            <button
              tabIndex={-1}
              aria-hidden="true"
              onClick={handleClose}
              className={classnames(
                'fixed inset-0 w-screen h-screen bg-mystic z-40 transition outline-none cursor-default',
                {
                  'visible opacity-50': open,
                  'invisible opacity-0 ': !open,
                }
              )}
            />
          </div>
        </FocusTrap>
      </TooltipPortal>
    </div>
  );
};

export default Tooltip;
