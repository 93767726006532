import React from 'react';
import { useHistory } from 'react-router-dom';

import { BasicLayout, Card, Footer } from 'src/common';

const Pause = () => {
  const history = useHistory();

  const handleResume = () => {
    history.replace('/resume');
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="cooler"
        title="Need to put your quote on ice?"
        subtitle="That's cool with us. Log back in any time and we'll pick up where you left off."
      />
      <Footer>
        <Footer.Next rounded label="Resume Quote" onClick={handleResume} />
      </Footer>
    </BasicLayout>
  );
};

export default Pause;
